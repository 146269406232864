let localUrls = {}
try {
  localUrls = require('./mfeLocalUrls')
} catch (ex) {}

/**
use the file above to point to local mfes without using the mfe plugin
create entries like the following:

module.exports = {
  platformApplications: 'http://localhost:8810/contrast-security/remoteEntry.js'
}
*/

export const getDefaultUrl = () => {
  const mfeURL = process.env.USE_LOCAL_MFE_FILES
    ? '/Contrast/mfe'
    : 'https://teamserver-staging.contsec.com/Contrast/mfe'

  return {
    contrastBase: `${mfeURL}/contrastBase/remoteEntry.js`,
    orgSettings: `${mfeURL}/orgSettings/remoteEntry.js`,
    superadmin: `${mfeURL}/superadmin/remoteEntry.js`,
    platformApplications: `${mfeURL}/applications/remoteEntry.js`,
    platformRepositories: `${mfeURL}/platformRepositories/remoteEntry.js`,
    platformAuthz: `${mfeURL}/platformAuthz/remoteEntry.js`,
    policyManagement: `${mfeURL}/policyManagement/remoteEntry.js`,
    platformReports: `${mfeURL}/platformReports/remoteEntry.js`,
    attackEvents: `${mfeURL}/attackEvents/remoteEntry.js`,
    platformUserSettings: `${mfeURL}/platformUserSettings/remoteEntry.js`
  }
}

export const getUrl = () => {
  if (window.__MFE__ && Object.keys(window.__MFE__).length !== 0) {
    return Object.assign({}, window.getDefaultUrl(), window.__MFE__)
  }

  if (Object.keys(localUrls).length > 0) {
    return { ...window.getDefaultUrl(), ...localUrls }
  }

  const previousMFESettings = JSON.parse(localStorage.getItem('mfeUrls'))
  if (
    previousMFESettings !== null &&
    Object.keys(previousMFESettings).length !== 0
  ) {
    return Object.assign({}, window.getDefaultUrl(), previousMFESettings)
  }

  return window.getDefaultUrl()
}

// Set on the window object so chrome plugins have access to the data
window.getDefaultUrl = getDefaultUrl
window.getUrl = getUrl
